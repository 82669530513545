import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { withUnpublishedPreview } from 'gatsby-source-prismic';
import ContentPage from 'templates/content';
import SavingsHomePage from 'templates/savings-index';
import SavingsContentPage from 'templates/savings-content';
import BlogHomePage from 'templates/blog-index';
import BlogArticlePage from 'templates/blog-article';
import { Masthead } from 'molecules';

import 'scss/main.scss';
import { Shell } from 'layout';

const defaultProps = {
    className: '',
};

const propTypes = {
    className: PropTypes.string,
};

function NotFoundPage({
    data,
    location: {
        pathname,
    },
}) {
    const siteData = data?.prismicSite;

    return (
        <Shell
            pathname={pathname}
            seoTitle="Page Not Found"
            siteData={siteData}
        >
            <main className="l-grid__main" id="main-content">
                <Masthead
                    ctaButtonText="Back to Home"
                    ctaButtonUrl={{link_type: 'Document', type: 'home_page', uid: ''}}
                    ctaColour="Green"
                    description="The page you are looking for could not be found."
                    strapline="404"
                    title="Page Not Found"
                />
            </main>
        </Shell>
    );
}

export const query = graphql`
    query {
        prismicSite {
            ...FooterQuery
        }
        prismicSite {
            ...HeaderQuery
        }
        prismicSite {
            ...NavQuery
        }
    }
`;

NotFoundPage.propTypes = propTypes;
NotFoundPage.defaultProps = defaultProps;

// If an unpublished `page` document is previewed, PageTemplate will be rendered.
export default withUnpublishedPreview(NotFoundPage, {
    templateMap: {
        blog_home_page: BlogHomePage,
        blog_article_page: BlogArticlePage,
        content_page: ContentPage,
        savings_content_page: SavingsContentPage,
        savings_home_page: SavingsHomePage,
    },
})
