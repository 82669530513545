import React from 'react';
import { graphql } from 'gatsby';
import { withPreview } from 'gatsby-source-prismic';
import PropTypes from 'prop-types';
import { Shell } from 'layout';
import { Pagination } from 'molecules';
import { BlogListing, MastheadBlogCategories } from 'organisms';
import { renderComponents } from 'utility';

import '../scss/main.scss';

const defaultProps = {};

const propTypes = {
    location: PropTypes.shape({
        pathname: PropTypes.string,
    }).isRequired,
    pageContext: PropTypes.shape({
        nextPagePath: PropTypes.string,
        numberOfPages: PropTypes.number,
        pageNumber: PropTypes.number,
        previousPagePath: PropTypes.string,
    }).isRequired,
};

function BlogIndexPage({
    data,
    location: {
        pathname,
    },
    pageContext,
}) {
    const doc = data?.prismicBlogHomePage?.data;
    const siteData = data?.prismicSite;
    const masthead = {
        description: doc?.description?.text,
        backgroundImage: doc?.background_image,
        strapline: doc?.strapline?.text,
        title: doc?.title?.text,
        video: doc?.background_video,
    };
    const pageDescription = doc?.page_meta_description?.text;
    const pageSocialImage = doc?.page_social_share_image?.url;
    const pageTitle = doc?.page_meta_title?.text;
    const components = doc?.body;
    let categories = [];
    doc?.category_links.forEach(({ category_link }) => {
        categories.push({
            icon: category_link?.document?.data?.category_icon,
            name: category_link?.document?.data?.category_name?.text,
        });
    });
    let blogListing = [];
    data?.allPrismicBlogArticlePage?.nodes.forEach(node => {
        blogListing.push({
            category: node?.data?.category?.document?.data?.category_name?.text,
            date: node?.data?.date,
            dateFormatted: node?.data?.date_formatted,
            description: {
                text: node?.data?.description?.text,
            },
            image: node?.data?.image,
            link: {
                link_type: 'Document',
                type: node?.type,
                uid: node?.uid,
            },
            title: {
                text: node?.data?.title?.text,
            },
        });
    });
    const pageUrl = `${process.env.GATSBY_SITE_URL}${pathname.substring(1)}`;

    return (
        <Shell
            pathname={pathname}
            seoDescription={pageDescription}
            seoTitle={pageTitle}
            seoImage={pageSocialImage}
            siteData={siteData}
        >
            <main className="l-grid__main" id="main-content">
                {(masthead?.description || masthead?.backgroundImage?.url || masthead?.strapline || masthead?.title || masthead?.video?.url) && (
                    <MastheadBlogCategories
                        categories={categories}
                        description={masthead.description}
                        pageUrl={pageUrl}
                        image={masthead.backgroundImage}
                        socialShare={true}
                        strapline={masthead.strapline}
                        title={masthead.title}
                        video={masthead.video}
                    />
                )}
                <BlogListing
                    listing={blogListing}
                />
                {(pageContext.nextPagePath || pageContext.previousPagePath) && (
                    <Pagination
                        nextPageUrl={pageContext.nextPagePath}
                        prevPageUrl={pageContext.previousPagePath}
                    />
                )}
                {components?.map(component => (
                    renderComponents(component, component?.slice_type, pathname)
                ))}
            </main>
        </Shell>
    );
}

export const query = graphql`
    query($skip: Int, $limit: Int) {
        prismicSite {
            ...FooterQuery
        }
        prismicSite {
            ...HeaderQuery
        }
        prismicSite {
            ...NavQuery
        }
        allPrismicBlogArticlePage(
            limit: $limit,
            skip: $skip,
            sort: {
                fields: data___date,
                order: DESC
            }
        ) {
            nodes {
                data {
                    category {
                        document {
                            ... on PrismicCategory {
                                data {
                                    category_icon {
                                        alt
                                        dimensions {
                                            height
                                            width
                                        }
                                        url
                                    }
                                    category_name {
                                        text
                                    }
                                }
                            }
                        }
                    }
                    date
                    date_formatted: date(formatString: "Do MMMM YYYY")
                    description {
                        text
                    }
                    image {
                        alt
                        dimensions {
                            height
                            width
                        }
                        url
                    }
                    title {
                        text
                    }
                }
                type
                uid
            }
        }
        prismicBlogHomePage {
            data {
                category_links {
                    category_link {
                        document {
                            ... on PrismicCategory {
                                data {
                                    category_icon {
                                        alt
                                        dimensions {
                                            height
                                            width
                                        }
                                        url
                                    }
                                    category_name {
                                        text
                                    }
                                }
                            }
                        }
                    }
                }
                body {
                    ... on PrismicBlogHomePageBodyAccordion {
                        items {
                            content {
                                raw
                            }
                            title {
                                text
                            }
                        }
                        slice_type
                    }
                    ... on PrismicBlogHomePageBodyBlockquote {
                        primary {
                            author_link {
                                link_type
                                type
                                uid
                                url
                            }
                            author_name {
                                text
                            }
                            background_image {
                                alt
                                dimensions {
                                    height
                                    width
                                }
                                url
                            }
                            quote {
                                text
                            }
                        }
                        slice_type
                    }
                    ... on PrismicBlogHomePageBodyContentImage1 {
                        primary {
                            button_text {
                                text
                            }
                            button_url {
                                link_type
                                type
                                uid
                                url
                            }
                            content {
                                raw
                            }
                            table_colour
                            image_1 {
                                alt
                                dimensions {
                                    height
                                    width
                                }
                                url
                            }
                            image_2 {
                                alt
                                dimensions {
                                    height
                                    width
                                }
                                url
                            }
                            image_3 {
                                alt
                                dimensions {
                                    height
                                    width
                                }
                                url
                            }
                            image_4 {
                                alt
                                dimensions {
                                    height
                                    width
                                }
                                url
                            }
                            image_5 {
                                alt
                                dimensions {
                                    height
                                    width
                                }
                                url
                            }
                            reverse_layout
                            image_size
                            title {
                                text
                            }
                            title_icon {
                                alt
                                dimensions {
                                    height
                                    width
                                }
                                url
                            }
                        }
                        slice_type
                    }
                    ... on PrismicBlogHomePageBodyChart {
                        items {
                            chart_label {
                                text
                            }
                            chart_percentage
                        }
                        primary {
                            chart_colour
                            chart_content {
                                raw
                            }
                            chart_graph_label {
                                text
                            }
                            chart_title {
                                text
                            }
                        }
                        slice_type
                    }
                    ... on PrismicBlogHomePageBodyContent {
                        primary {
                            content_content {
                                raw
                            }
                            table_colour
                            content_title {
                                text
                            }
                        }
                        slice_type
                    }
                    ... on PrismicBlogHomePageBodyDownload {
                        primary {
                            download_button_2_link {
                                link_type
                                size
                                type
                                uid
                                url
                            }
                            download_button_2_text {
                                text
                            }
                            download_button_link {
                                link_type
                                size
                                type
                                uid
                                url
                            }
                            download_button_text {
                                text
                            }
                            download_file_name {
                                text
                            }
                        }
                        slice_type
                    }
                    ... on PrismicBlogHomePageBodyImage {
                        primary {
                            image_caption {
                                text
                            }
                            image_image {
                                alt
                                dimensions {
                                    height
                                    width
                                }
                                url
                            }
                        }
                        slice_type
                    }
                    ... on PrismicBlogHomePageBodyLogoListing {
                        items {
                            logo_listing_link {
                                link_type
                                type
                                uid
                                url
                            }
                            logo_listing_logo {
                                alt
                                dimensions {
                                    height
                                    width
                                }
                                url
                            }
                        }
                        slice_type
                    }
                    ... on PrismicBlogHomePageBodyQuickLinks {
                        items {
                            quick_links_button_text {
                                text
                            }
                            quick_links_button_textbutton_background_colour
                            quick_links_button_textbutton_url {
                                link_type
                                type
                                uid
                                url
                            }
                        }
                        slice_type
                    }
                    ... on PrismicBlogHomePageBodyTeamListing {
                        items {
                            team_listing_content {
                                raw
                            }
                            team_listing_image {
                                alt
                                dimensions {
                                    height
                                    width
                                }
                                url
                            }
                            team_listing_job_title {
                                text
                            }
                            team_listing_name {
                                text
                            }
                        }
                        primary {
                            team_listing_grid_layout
                        }
                        slice_type
                    }
                    ... on PrismicBlogHomePageBodyValueListing {
                        items {
                            value_listing_value_content {
                                raw
                            }
                            value_listing_value_title {
                                text
                            }
                        }
                        primary {
                            value_listing_image_1 {
                                alt
                                dimensions {
                                height
                                width
                                }
                                url
                            }
                            value_listing_image_2 {
                                alt
                                dimensions {
                                    height
                                    width
                                }
                                url
                            }
                            value_listing_title {
                                text
                            }
                        }
                        slice_type
                    }
                    ... on PrismicBlogHomePageBodyVideo {
                        primary {
                            video_caption {
                                text
                            }
                            video_video {
                                html
                            }
                        }
                        slice_type
                    }
                    ... on PrismicBlogHomePageBodyLoanForm {
                        primary {
                            loan_form_call_time_field_label {
                                text
                            }
                            loan_form_content {
                                text
                            }
                            loan_form_email_field_label {
                                text
                            }
                            loan_form_farm_type_field_label {
                                text
                            }
                            loan_form_farm_type_field_options {
                                text
                            }
                            loan_form_footer_content {
                                raw
                            }
                            loan_form_land_farmed_field_label {
                                text
                            }
                            loan_form_land_farmed_field_options {
                                text
                            }
                            loan_form_last_name_field_label {
                                text
                            }
                            loan_form_loan_amount_field_label {
                                text
                            }
                            loan_form_loan_reason_field_label {
                                text
                            }
                            loan_form_loan_reason_field_options {
                                text
                            }
                            loan_form_loan_value_field_label {
                                text
                            }
                            loan_form_loan_value_field_options {
                                text
                            }
                            loan_form_message_field_label {
                                text
                            }
                            loan_form_name_field_label {
                                text
                            }
                            loan_form_phone_field_label {
                                text
                            }
                            loan_form_postcode_field_label {
                                text
                            }
                            loan_form_submit_button_text {
                                text
                            }
                            loan_form_subscribe_email_checkbox_label {
                                text
                            }
                            loan_form_subscribe_none_checkbox_label {
                                text
                            }
                            loan_form_subscribe_post_checkbox_label {
                                text
                            }
                            loan_form_subscribe_sms_checkbox_label {
                                text
                            }
                            loan_form_subscribe_tel_checkbox_label {
                                text
                            }
                            loan_form_term_length_field_label {
                                text
                            }
                            loan_form_term_length_field_options {
                                text
                            }
                            loan_form_title {
                                text
                            }
                            loan_form_product {
                                text
                            }
                        }
                        slice_type
                    }
                    ... on PrismicBlogHomePageBodyContactForm {
                        primary {
                            contact_form_call_time_field_label {
                                text
                            }
                            contact_form_content {
                                raw
                            }
                            contact_form_email_field_label {
                                text
                            }
                            contact_form_farm_size_field_label {
                                text
                            }
                            contact_form_farm_type_field_label {
                                text
                            }
                            contact_form_farm_type_field_options {
                                text
                            }
                            contact_form_farm_type_zen_sell_field_name {
                                text
                            }
                            contact_form_footer_content {
                                raw
                            }
                            contact_form_input_supplier_field_label {
                                text
                            }
                            contact_form_land_farmed_field_label {
                                text
                            }
                            contact_form_land_farmed_field_options {
                                text
                            }
                            contact_form_last_name_field_label {
                                text
                            }
                            contact_form_loan_value_field_label {
                                text
                            }
                            contact_form_loan_value_field_options {
                                text
                            }
                            contact_form_message_field_label {
                                text
                            }
                            contact_form_name_field_label {
                                text
                            }
                            contact_form_phone_field_label {
                                text
                            }
                            contact_form_postcode_field_label {
                                text
                            }
                            contact_form_submit_button_text {
                                text
                            }
                            contact_form_subscribe_email_checkbox_label {
                                text
                            }
                            contact_form_subscribe_none_checkbox_label {
                                text
                            }
                            contact_form_subscribe_post_checkbox_label {
                                text
                            }
                            contact_form_subscribe_sms_checkbox_label {
                                text
                            }
                            contact_form_subscribe_tel_checkbox_label {
                                text
                            }
                            contact_form_title {
                                text
                            }
                            contact_form_product {
                                text
                            }
                        }
                        slice_type
                    }
                    ... on PrismicBlogHomePageBodyCarbonCalculator {
                        primary {
                            cc_content {
                                raw
                            }
                            cc_image {
                                alt
                                dimensions {
                                    height
                                    width
                                }
                                url
                            }
                            cc_panel_1_carbon_label {
                                text
                            }
                            cc_panel_1_deposit_label {
                                text
                            }
                            cc_panel_1_interest_label {
                                text
                            }
                            cc_panel_1_tree_label {
                                text
                            }
                            cc_panel_2_carbon_label {
                                text
                            }
                            cc_panel_2_deposit_label {
                                text
                            }
                            cc_panel_2_interest_label {
                                text
                            }
                            cc_panel_2_tree_label {
                                text
                            }
                            cc_panel_icon {
                                alt
                                dimensions {
                                    height
                                    width
                                }
                                url
                            }
                            cc_tab_1_name {
                                text
                            }
                            cc_tab_2_name {
                                text
                            }
                            cc_title {
                                text
                            }
                        }
                        slice_type
                    }
                    ... on PrismicBlogHomePageBodyNoticeMessage {
                        primary {
                            notice_message_description {
                                raw
                            }
                            notice_message_title {
                                text
                            }
                        }
                        slice_type
                    }
                }
                background_image {
                    alt
                    dimensions {
                        height
                        width
                    }
                    url
                }
                background_video {
                    url
                }
                description {
                    text
                }
                strapline {
                    text
                }
                title {
                    text
                }
                page_meta_description {
                    text
                }
                page_meta_title {
                    text
                }
                page_social_share_image {
                    url
                }
            }
        }
    }
`;

BlogIndexPage.propTypes = propTypes;
BlogIndexPage.defaultProps = defaultProps;

export default withPreview(BlogIndexPage);
