import React from 'react';
import { graphql } from 'gatsby';
import { withPreview } from 'gatsby-source-prismic';
import PropTypes from 'prop-types';
import { Shell } from 'layout';
import { MastheadBlogIndividual, SocialShare } from 'molecules';
import { BlogListingRelated } from 'organisms';
import { renderComponents } from 'utility';

import '../scss/main.scss';

const defaultProps = {};

const propTypes = {
    location: PropTypes.shape({
        pathname: PropTypes.string,
    }).isRequired,
};

function BlogArticlePage({
    data,
    location: {
        pathname,
    },
}) {
    const doc = data?.prismicBlogArticlePage?.data;
    const siteData = data?.prismicSite;
    const author = doc?.author?.document?.data;
    const category = doc?.category?.document?.data;
    const date = doc?.date;
    const dateFormatted = doc?.date_formatted;
    const description = doc?.description?.text;
    const image = doc?.image;
    const title = doc?.title?.text;
    let relatedBlogPosts = [];
    doc?.blog_articles.forEach(({blog_article}) => {
        relatedBlogPosts.push({
            image: blog_article?.document?.data?.image,
            link: {
                link_type: 'Document',
                type: blog_article?.document?.type,
                uid: blog_article?.document?.uid,
            },
            title: {
                text: blog_article?.document?.data?.title?.text,
            },
        });
    });
    const pageDescription = doc?.page_meta_description?.text;
    const pageSocialImage = doc?.page_social_share_image?.url;
    const pageTitle = doc?.page_meta_title?.text;
    const pageUrl = `${process.env.GATSBY_SITE_URL}${pathname.substring(1)}`;
    const components = doc?.body;

    const structuredData = {
        '@context': 'http://schema.org',
        '@type': 'NewsArticle',
        author: {
            '@type': 'Person',
            name: (author?.team_name?.text) ? author?.team_name?.text : 'Oxbury',
        },
        dateModified: date,
        datePublished: date,
        description: description || pageDescription,
        headline: title || pageTitle,
        image: (pageSocialImage) ? pageSocialImage : image?.url,
        mainEntityOfPage: {
            '@type': 'WebPage',
            '@id': pageUrl,
        },
        publisher: {
            '@type': 'Organization',
            name: 'Oxbury',
            logo: {
                '@type': 'ImageObject',
                url: `${process.env.GATSBY_SITE_URL}icons/icon-512x512.png`,
            },
        },
    };

    return (
        <Shell
            className="t-blog-article"
            pathname={pathname}
            seoDescription={pageDescription}
            seoTitle={pageTitle}
            seoImage={pageSocialImage}
            siteData={siteData}
        >
            <main className="l-grid__main" id="main-content">
                <MastheadBlogIndividual
                    authorName={author?.team_name?.text}
                    authorImage={author?.team_image}
                    category={category?.category_name?.text}
                    date={date}
                    dateFormatted={dateFormatted}
                    image={image}
                    title={title}
                />
                <div className="l-grid l-grid--container t-blog-article__container">
                    <div className="t-blog-article__social-container top">
                        <SocialShare pageUrl={pageUrl} />
                    </div>
                    <div className="t-blog-article__content-container">
                        {description && (
                            <p className="t-blog-article__description">{description}</p>
                        )}
                        {components?.map(component => (
                            renderComponents(component, component?.slice_type)
                        ))}
                    </div>
                    <aside className="t-blog-article__aside-container">
                        {relatedBlogPosts.length > 1 && (
                            <BlogListingRelated
                                listing={relatedBlogPosts}
                            />
                        )}
                    </aside>
                    <div className="t-blog-article__social-container bottom">
                        <SocialShare pageUrl={pageUrl} />
                    </div>
                </div>
            </main>
            <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(structuredData) }} />
        </Shell>
    );
}

export const query = graphql`
    query($id: String!) {
        prismicSite {
            ...FooterQuery
        }
        prismicSite {
            ...HeaderQuery
        }
        prismicSite {
            ...NavQuery
        }
        prismicBlogArticlePage(id: { eq: $id }) {
            data {
                author {
                    document {
                        ... on PrismicTeam {
                            data {
                                team_image {
                                    alt
                                    dimensions {
                                        height
                                        width
                                    }
                                    url
                                }
                                team_name {
                                    text
                                }
                            }
                        }
                    }
                }
                blog_articles {
                    blog_article {
                        document {
                            ... on PrismicBlogArticlePage {
                                data {
                                    image {
                                        alt
                                        dimensions {
                                            height
                                            width
                                        }
                                        url
                                    }
                                    title {
                                        text
                                    }
                                }
                                type
                                uid
                            }
                        }
                    }
                }
                category {
                    document {
                        ... on PrismicCategory {
                            data {
                                category_icon {
                                    alt
                                    dimensions {
                                        height
                                        width
                                    }
                                    url
                                }
                                category_name {
                                    text
                                }
                            }
                        }
                    }
                }
                date
                date_formatted: date(formatString: "Do MMMM YYYY")
                description {
                    text
                }
                image {
                    alt
                    dimensions {
                        height
                        width
                    }
                    url
                }
                title {
                    text
                }
                page_meta_description {
                    text
                }
                page_meta_title {
                    text
                }
                page_social_share_image {
                    url
                }
                body {
                    ... on PrismicBlogArticlePageBodyContent {
                        primary {
                            content_title {
                                text
                            }
                            content_content {
                                raw
                            }
                            table_colour
                        }
                        slice_type
                    }
                    ... on PrismicBlogArticlePageBodyImage {
                        primary {
                            image_caption {
                                text
                            }
                            image_image {
                                alt
                                dimensions {
                                    height
                                    width
                                }
                                url
                            }
                        }
                        slice_type
                    }
                    ... on PrismicBlogArticlePageBodyVideo {
                        primary {
                            video_caption {
                                text
                            }
                            video_video {
                                html
                            }
                        }
                        slice_type
                    }
                }
            }
        }
    }
`;

BlogArticlePage.propTypes = propTypes;
BlogArticlePage.defaultProps = defaultProps;

export default withPreview(BlogArticlePage);
