import { graphql } from 'gatsby';
import React from 'react';
import { withPreview } from 'gatsby-source-prismic';
import PropTypes from 'prop-types';
import { Shell } from 'layout';
import { Masthead, SectionHeader } from 'molecules';
import { TabListing } from 'organisms';
import { renderComponents } from 'utility';

import 'scss/main.scss';

const defaultProps = {};

const propTypes = {
    location: PropTypes.shape({
        pathname: PropTypes.string,
    }).isRequired,
};

function SavingsHomePage({
    data,
    location: {
        pathname,
    },
}) {
    const doc = data?.prismicSavingsHomePage?.data;
    const siteData = data?.prismicSite;
    const masthead = {
        description: doc?.description?.text,
        backgroundImage: doc?.background_image,
        strapline: doc?.strapline?.text,
        title: doc?.title?.text,
        video: doc?.background_video,
    };
    const pageDescription = doc?.page_meta_description?.text;
    const pageSocialImage = doc?.page_social_share_image?.url;
    const pageTitle = doc?.page_meta_title?.text;
    const components = doc?.body;
    const components1 = doc?.body1;

    const tabTitle = doc?.tab_title;
    const tabTitleIcon = doc?.tab_title_icon;
    let tabListing = [];
    tabListing.push(doc?.tab_title_1);
    tabListing.push(doc?.tab_title_2);
    tabListing.push(doc?.tab_title_3);
    let panelListing = [];
    panelListing.push(doc?.body2);
    panelListing.push(doc?.body3);
    panelListing.push(doc?.body4);
    const hasTabs = (tabListing[0]?.text || tabListing[1]?.text || tabListing[2]?.text);
    const hasPanels = (panelListing[0].length > 0 || panelListing[1].length > 0 || panelListing[2].length > 0);

    return (
        <Shell
            pathname={pathname}
            seoDescription={pageDescription}
            seoTitle={pageTitle}
            seoImage={pageSocialImage}
            siteData={siteData}
        >
            <main className="l-grid__main" id="main-content">
                {(masthead?.description || masthead?.backgroundImage?.url || masthead?.strapline || masthead?.title || masthead?.video?.url) && (
                    <Masthead
                        description={masthead.description}
                        image={masthead.backgroundImage}
                        strapline={masthead.strapline}
                        title={masthead.title}
                        video={masthead.video}
                    />
                )}
                {components?.map(component => (
                    renderComponents(component, component?.slice_type)
                ))}
                {(hasTabs && hasPanels) && (
                    <div className="l-shell-grey-bg">
                        {tabTitle && (
                            <SectionHeader
                                className="m-section-header--align-center m-section-header--no-underline"
                                icon={tabTitleIcon}
                                title={tabTitle?.text}
                            />
                        )}
                        {(tabListing && panelListing) && (
                            <TabListing
                                panelListing={panelListing}
                                tabListing={tabListing}
                            />
                        )}
                    </div>
                )}
                {components1?.map(component => (
                    renderComponents(component, component?.slice_type)
                ))}
            </main>
        </Shell>
    );
}

export const query = graphql`
    query($id: String!) {
        prismicSite {
            ...FooterQuery
        }
        prismicSite {
            ...HeaderQuery
        }
        prismicSite {
            ...NavQuery
        }
        prismicSavingsHomePage(id: { eq: $id }) {
            data {
                body {
                    ... on PrismicSavingsHomePageBodyAccordion {
                        items {
                            content {
                                raw
                            }
                            title {
                                text
                            }
                        }
                        slice_type
                    }
                    ... on PrismicSavingsHomePageBodyContent {
                        primary {
                            content_content {
                                raw
                            }
                            table_colour
                            content_title {
                                text
                        }
                        }
                        slice_type
                    }
                    ... on PrismicSavingsHomePageBodyContentImage {
                        primary {
                            button_text {
                                text
                            }
                            button_url {
                                link_type
                                type
                                uid
                                url
                            }
                            content {
                                raw
                            }
                            table_colour
                            image_1 {
                                alt
                                dimensions {
                                    height
                                    width
                                }
                                url
                            }
                            image_2 {
                                alt
                                dimensions {
                                    height
                                    width
                                }
                                url
                            }
                            image_3 {
                                alt
                                dimensions {
                                    height
                                    width
                                }
                                url
                            }
                            image_4 {
                                alt
                                dimensions {
                                    height
                                    width
                                }
                                url
                            }
                            image_5 {
                                alt
                                dimensions {
                                    height
                                    width
                                }
                                url
                            }
                            reverse_layout
                            image_size
                            title {
                                text
                            }
                            title_icon {
                                alt
                                dimensions {
                                    height
                                    width
                                }
                                url
                            }
                        }
                        slice_type
                    }
                    ... on PrismicSavingsHomePageBodyImage {
                        primary {
                            image_caption {
                                text
                            }
                            image_image {
                                alt
                                dimensions {
                                    height
                                    width
                                }
                                url
                            }
                        }
                        slice_type
                    }
                    ... on PrismicSavingsHomePageBodyVideo {
                        primary {
                            video_caption {
                                text
                            }
                            video_video {
                                html
                            }
                        }
                        slice_type
                    }
                }
                body1 {
                    ... on PrismicSavingsHomePageBody1Accordion {
                        items {
                            content {
                                raw
                            }
                            title {
                                text
                            }
                        }
                        slice_type
                    }
                    ... on PrismicSavingsHomePageBody1Blockquote {
                        primary {
                            author_link {
                                link_type
                                type
                                uid
                                url
                            }
                            author_name {
                                text
                            }
                            background_image {
                                alt
                                dimensions {
                                    height
                                    width
                                }
                                url
                            }
                            quote {
                                text
                            }
                        }
                        slice_type
                    }
                    ... on PrismicSavingsHomePageBody1Chart {
                        items {
                            chart_label {
                                text
                            }
                            chart_percentage
                        }
                        primary {
                            chart_colour
                            chart_content {
                                raw
                            }
                            chart_graph_label {
                                text
                            }
                            chart_title {
                                text
                            }
                        }
                        slice_type
                    }
                    ... on PrismicSavingsHomePageBody1Content {
                        primary {
                            content_content {
                                raw
                            }
                            table_colour
                            content_title {
                                text
                        }
                        }
                        slice_type
                    }
                    ... on PrismicSavingsHomePageBody1ContentImage {
                        primary {
                            button_text {
                                text
                            }
                            button_url {
                                link_type
                                type
                                uid
                                url
                            }
                            content {
                                raw
                            }
                            table_colour
                            image_1 {
                                alt
                                dimensions {
                                    height
                                    width
                                }
                                url
                            }
                            image_2 {
                                alt
                                dimensions {
                                    height
                                    width
                                }
                                url
                            }
                            image_3 {
                                alt
                                dimensions {
                                    height
                                    width
                                }
                                url
                            }
                            image_4 {
                                alt
                                dimensions {
                                    height
                                    width
                                }
                                url
                            }
                            image_5 {
                                alt
                                dimensions {
                                    height
                                    width
                                }
                                url
                            }
                            reverse_layout
                            image_size
                            title {
                                text
                            }
                            title_icon {
                                alt
                                dimensions {
                                    height
                                    width
                                }
                                url
                            }
                        }
                        slice_type
                    }
                    ... on PrismicSavingsHomePageBody1Download {
                        primary {
                            download_button_2_link {
                                link_type
                                size
                                type
                                uid
                                url
                            }
                            download_button_2_text {
                                text
                            }
                            download_button_link {
                                link_type
                                size
                                type
                                uid
                                url
                            }
                            download_button_text {
                                text
                            }
                            download_file_name {
                                text
                            }
                        }
                        slice_type
                    }
                    ... on PrismicSavingsHomePageBody1Image {
                        primary {
                            image_caption {
                                text
                            }
                            image_image {
                                alt
                                dimensions {
                                    height
                                    width
                                }
                                url
                            }
                        }
                        slice_type
                    }
                    ... on PrismicSavingsHomePageBody1TeamListing {
                        items {
                            team_listing_content {
                                raw
                            }
                            team_listing_image {
                                alt
                                dimensions {
                                    height
                                    width
                                }
                                url
                            }
                            team_listing_job_title {
                                text
                            }
                            team_listing_name {
                                text
                            }
                        }
                        primary {
                            team_listing_grid_layout
                        }
                        slice_type
                    }
                    ... on PrismicSavingsHomePageBody1ValueListing {
                        items {
                            value_listing_value_content {
                                raw
                            }
                            value_listing_value_title {
                                text
                            }
                        }
                        primary {
                            value_listing_image_1 {
                                alt
                                dimensions {
                                height
                                width
                                }
                                url
                            }
                            value_listing_image_2 {
                                alt
                                dimensions {
                                    height
                                    width
                                }
                                url
                            }
                            value_listing_title {
                                text
                            }
                        }
                        slice_type
                    }
                    ... on PrismicSavingsHomePageBody1LogoListing {
                        items {
                            logo_listing_link {
                                link_type
                                type
                                uid
                                url
                            }
                            logo_listing_logo {
                                alt
                                dimensions {
                                    height
                                    width
                                }
                                url
                            }
                        }
                        slice_type
                    }
                    ... on PrismicSavingsHomePageBody1QuickLinks {
                        items {
                            quick_links_button_text {
                                text
                            }
                            quick_links_button_textbutton_background_colour
                            quick_links_button_textbutton_url {
                                link_type
                                type
                                uid
                                url
                            }
                        }
                        slice_type
                    }
                    ... on PrismicSavingsHomePageBody1Video {
                        primary {
                            video_caption {
                                text
                            }
                            video_video {
                                html
                            }
                        }
                        slice_type
                    }
                    ... on PrismicSavingsHomePageBody1LoanForm {
                        primary {
                            loan_form_call_time_field_label {
                                text
                            }
                            loan_form_content {
                                text
                            }
                            loan_form_email_field_label {
                                text
                            }
                            loan_form_farm_type_field_label {
                                text
                            }
                            loan_form_farm_type_field_options {
                                text
                            }
                            loan_form_footer_content {
                                raw
                            }
                            loan_form_land_farmed_field_label {
                                text
                            }
                            loan_form_land_farmed_field_options {
                                text
                            }
                            loan_form_last_name_field_label {
                                text
                            }
                            loan_form_loan_amount_field_label {
                                text
                            }
                            loan_form_loan_reason_field_label {
                                text
                            }
                            loan_form_loan_reason_field_options {
                                text
                            }
                            loan_form_loan_value_field_label {
                                text
                            }
                            loan_form_loan_value_field_options {
                                text
                            }
                            loan_form_message_field_label {
                                text
                            }
                            loan_form_name_field_label {
                                text
                            }
                            loan_form_phone_field_label {
                                text
                            }
                            loan_form_postcode_field_label {
                                text
                            }
                            loan_form_submit_button_text {
                                text
                            }
                            loan_form_subscribe_email_checkbox_label {
                                text
                            }
                            loan_form_subscribe_none_checkbox_label {
                                text
                            }
                            loan_form_subscribe_post_checkbox_label {
                                text
                            }
                            loan_form_subscribe_sms_checkbox_label {
                                text
                            }
                            loan_form_subscribe_tel_checkbox_label {
                                text
                            }
                            loan_form_term_length_field_label {
                                text
                            }
                            loan_form_term_length_field_options {
                                text
                            }
                            loan_form_title {
                                text
                            }
                            loan_form_product {
                                text
                            }
                        }
                        slice_type
                    }
                    ... on PrismicSavingsHomePageBody1ContactForm {
                        primary {
                            contact_form_call_time_field_label {
                                text
                            }
                            contact_form_content {
                                raw
                            }
                            contact_form_email_field_label {
                                text
                            }
                            contact_form_farm_size_field_label {
                                text
                            }
                            contact_form_farm_type_field_label {
                                text
                            }
                            contact_form_farm_type_field_options {
                                text
                            }
                            contact_form_footer_content {
                                raw
                            }
                            contact_form_input_supplier_field_label {
                                text
                            }
                            contact_form_land_farmed_field_label {
                                text
                            }
                            contact_form_land_farmed_field_options {
                                text
                            }
                            contact_form_last_name_field_label {
                                text
                            }
                            contact_form_loan_value_field_label {
                                text
                            }
                            contact_form_loan_value_field_options {
                                text
                            }
                            contact_form_message_field_label {
                                text
                            }
                            contact_form_name_field_label {
                                text
                            }
                            contact_form_phone_field_label {
                                text
                            }
                            contact_form_postcode_field_label {
                                text
                            }
                            contact_form_submit_button_text {
                                text
                            }
                            contact_form_subscribe_email_checkbox_label {
                                text
                            }
                            contact_form_subscribe_none_checkbox_label {
                                text
                            }
                            contact_form_subscribe_post_checkbox_label {
                                text
                            }
                            contact_form_subscribe_sms_checkbox_label {
                                text
                            }
                            contact_form_subscribe_tel_checkbox_label {
                                text
                            }
                            contact_form_title {
                                text
                            }
                            contact_form_product {
                                text
                            }
                        }
                        slice_type
                    }
                    ... on PrismicSavingsHomePageBody1CarbonCalculator {
                        primary {
                            cc_content {
                                raw
                            }
                            cc_image {
                                alt
                                dimensions {
                                    height
                                    width
                                }
                                url
                            }
                            cc_panel_1_carbon_label {
                                text
                            }
                            cc_panel_1_deposit_label {
                                text
                            }
                            cc_panel_1_interest_label {
                                text
                            }
                            cc_panel_1_tree_label {
                                text
                            }
                            cc_panel_2_carbon_label {
                                text
                            }
                            cc_panel_2_deposit_label {
                                text
                            }
                            cc_panel_2_interest_label {
                                text
                            }
                            cc_panel_2_tree_label {
                                text
                            }
                            cc_panel_icon {
                                alt
                                dimensions {
                                    height
                                    width
                                }
                                url
                            }
                            cc_tab_1_name {
                                text
                            }
                            cc_tab_2_name {
                                text
                            }
                            cc_title {
                                text
                            }
                        }
                        slice_type
                    }
                    ... on PrismicSavingsHomePageBody1NoticeMessage {
                        primary {
                            notice_message_description {
                                raw
                            }
                            notice_message_title {
                                text
                            }
                        }
                        slice_type
                    }
                }
                body2 {
                    ... on PrismicSavingsHomePageBody2Content {
                        primary {
                            content_content {
                                raw
                            }
                            table_colour
                        }
                        slice_type
                    }
                    ... on PrismicSavingsHomePageBody2Download {
                        primary {
                            download_button_link {
                                link_type
                                size
                                type
                                uid
                                url
                            }
                            download_button_text {
                                text
                            }
                            download_file_name {
                                text
                            }
                        }
                        slice_type
                    }
                    ... on PrismicSavingsHomePageBody2SavingsCta1 {
                        primary {
                            savings_cta_button_background_colour
                            savings_cta_button_text {
                                text
                            }
                            savings_cta_button_url {
                                link_type
                                type
                                uid
                                url
                            }
                            savings_cta_interest_rate {
                                text
                            }
                            savings_cta_interest_rate_label {
                                text
                            }
                            savings_cta_max
                            savings_cta_min
                            savings_cta_period {
                                text
                            }
                            savings_cta_title {
                                text
                            }
                        }
                        slice_type
                    }
                }
                body3 {
                    ... on PrismicSavingsHomePageBody3Content {
                        primary {
                            content_content {
                                raw
                            }
                            table_colour
                        }
                        slice_type
                    }
                    ... on PrismicSavingsHomePageBody3Download {
                        primary {
                            download_button_link {
                                link_type
                                size
                                type
                                uid
                                url
                            }
                            download_button_text {
                                text
                            }
                            download_file_name {
                                text
                            }
                        }
                        slice_type
                    }
                    ... on PrismicSavingsHomePageBody3SavingsCta {
                        primary {
                            savings_cta_button_background_colour
                            savings_cta_button_text {
                                text
                            }
                            savings_cta_button_url {
                                link_type
                                type
                                uid
                                url
                            }
                            savings_cta_interest_rate {
                                text
                            }
                            savings_cta_interest_rate_label {
                                text
                            }
                            savings_cta_max
                            savings_cta_min
                            savings_cta_period {
                                text
                            }
                            savings_cta_title {
                                text
                            }
                        }
                        slice_type
                    }
                }
                body4 {
                    ... on PrismicSavingsHomePageBody4Content {
                        primary {
                            content_content {
                                raw
                            }
                            table_colour
                        }
                        slice_type
                    }
                    ... on PrismicSavingsHomePageBody4Download {
                        primary {
                            download_button_link {
                                link_type
                                size
                                type
                                uid
                                url
                            }
                            download_button_text {
                                text
                            }
                            download_file_name {
                                text
                            }
                        }
                        slice_type
                    }
                    ... on PrismicSavingsHomePageBody4SavingsCta {
                        primary {
                            savings_cta_button_background_colour
                            savings_cta_button_text {
                                text
                            }
                            savings_cta_button_url {
                                link_type
                                type
                                uid
                                url
                            }
                            savings_cta_interest_rate {
                                text
                            }
                            savings_cta_interest_rate_label {
                                text
                            }
                            savings_cta_max
                            savings_cta_min
                            savings_cta_period {
                                text
                            }
                            savings_cta_title {
                                text
                            }
                        }
                        slice_type
                    }
                }
                background_image {
                    alt
                    dimensions {
                        height
                        width
                    }
                    url
                }
                background_video {
                    url
                }
                description {
                    text
                }
                strapline {
                    text
                }
                tab_title {
                    text
                }
                tab_title_icon {
                    alt
                    url
                }
                tab_title_1 {
                    text
                }
                tab_title_2 {
                    text
                }
                tab_title_3 {
                    text
                }
                title {
                    text
                }
                page_meta_description {
                    text
                }
                page_meta_title {
                    text
                }
                page_social_share_image {
                    url
                }
            }
        }
    }
`;

SavingsHomePage.propTypes = propTypes;
SavingsHomePage.defaultProps = defaultProps;

export default withPreview(SavingsHomePage);
